//RedirectLogin.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectToLogin() {
  const navigator = useNavigate();

  useEffect(() => {
    navigator('/login');
  }, []);

  return null;
}

export default RedirectToLogin