//Admin.js

import React from "react";
import { useAuth } from "../hooks/use-auth";
import { useEffect } from "react";
import axios from "../utils/utils";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEraser,
  faL,
  faNewspaper,
  faPenToSquare,
  faTrash,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  Button,
  Modal,
  Form,
  Pagination,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";

export default function Admin(props) {
  const [users, setUsers] = useState([]);
  const [addModal, showAddModal] = useState(false);
  const [expiryPanel, showExpiryPanel] = useState(false);
  const [showEditPanel, setEditPanel] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [showChangeModal, setChangePwdPanel] = useState(false);
  const [dataPwd, setPassword] = useState({});
  const [toastMsg, setToastMsg] = useState({
    status: "secondary",
    show: false,
    message: "",
  });
  const [showUpdatePwdModal, setShowUpdatePwdModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchEmail, setSearchEmail] = useState("");

  const navigator = useNavigate();
  const auth = useAuth();

  const handleRemoveUser = async (user) => {
    try {
      const response = await axios.post("/api/admin/user/delete", { user });
      setUsers(users.filter((u) => u.email !== user.email));
      setToastMsg({
        status: "success",
        show: true,
        message: "A user has been deleted!",
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to remove user!",
      });
      console.log("removing user error!");
    }
  };

  const handleRemoveHistoryUser = async (user) => {
    try {
      const response = await axios.post("/api/admin/user/history/delete", {
        user,
      });
      console.log("ok");
      setToastMsg({
        status: "success",
        show: true,
        message: "A user's history has been deleted!",
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to remove user's history!",
      });
      console.log("removing history");
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const email = form.email.value;
    const pwd = form.password.value;
    let expiryDate,
      amount = null;
    if (expiryPanel) {
      expiryDate = form.expiryDate.value;
      amount = form.amount.value;
    }
    const data = { name, email, pwd, expiryDate, amount };
    const now = new Date();
    try {
      const res = await axios.post("/api/admin/user/add", { data });
      const newUser = {
        ...res.data.user,
        // purchaseDate: new Date().toISOString().slice(0, 10), // Or handle date formatting as needed
      };
      // Update state with new user list
      setUsers((prevUsers) => [...prevUsers, newUser]);
      setToastMsg({
        status: "success",
        show: true,
        message: "A user has been added!",
      });
    } catch (err) {
      setToastMsg({
        status: "success",
        show: true,
        message: "Failed to add an user!",
      });
      // console.log('login error!', err)
    }
    showAddModal(false);
  };

  const handleExpiryPanel = (e) => {
    showExpiryPanel(e.target.checked);
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    const form = e.target;
    const totalLimit = form.amount.value;
    const expiryDate = form.expiryAt.value;
    if (expiryDate === "") {
      setEditPanel(false);
      return false;
    }
    console.log("editor: ", editUser);
    try {
      const res = await axios.post("/api/admin/user/setBarcodeLimit", {
        email: editUser.email,
        totalLimit, // Sending totalLimit as part of the request
        expiryDate,
      });
      console.log("ok");
      setUsers(
        users.map((u) => {
          if (u.email === editUser.email) {
            return {
              ...u,
              usedBarcodes: 0, // Reset used barcodes
              availableBarcodes: totalLimit, // Calculate new available barcodes
              expiryDate, // Update expiry date
              purchaseDate: new Date().toISOString().slice(0, 10),
            };
          }
          return u;
        })
      );
      setToastMsg({
        status: "success",
        show: true,
        message: "User's barcode limit and expiry date have been updated!",
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to update user's barcode limit and expiry date!",
      });
      console.log("error: ", err);
    }
    setEditPanel(false);
  };

  const handleUpdateUserPassword = async () => {
    try {
      await axios.post("/api/admin/user/updatepwd", {
        email: editUser.email,
        newPassword,
      });
      setToastMsg({
        status: "success",
        show: true,
        message: "User's password has been updated!",
      });
      setShowUpdatePwdModal(false); // Close the modal
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to update user's password!",
      });
      console.error("Error updating user password:", err);
    }
  };

  const handleChangeAdminPwd = async (e) => {
    e.preventDefault();
    if (dataPwd?.pwd === "" || dataPwd?.conPwd === "") {
      return false;
    }
    try {
      if (dataPwd.pwd !== dataPwd.conPwd) {
        setChangePwdPanel(false);
        return false;
      }
      const response = await axios.post("/api/admin/profile/changepwd", {
        data: dataPwd.pwd,
      });
      console.log("ok");
      setToastMsg({
        status: "success",
        show: true,
        message: "A user's password has been changed!",
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to change password!",
      });
      console.log("error", err);
    }
    setChangePwdPanel(false);
  };

  const handleChangePwd = (e) => {
    setPassword({
      ...dataPwd,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   async function fetchAllData() {
  //     try {
  //       const response = await axios.post("/api/admin/records/all", {});
  //       setUsers(response.data.users);
  //     } catch (err) {
  //       console.log("admin getting data error");
  //     }
  //   }
  //   fetchAllData();
  // }, [props]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.post(
          `/api/admin/records/all?page=${currentPage}&limit=50&email=${searchEmail}`,
          {}
        );
        setUsers(response.data.users);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        console.log("Error fetching users:", err);
      }
    };
    fetchUsers();
  }, [currentPage, searchEmail]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate pagination items
  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // Include this Pagination component where you want to show the pagination bar in your return statement
  const paginationBasic = (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );

  return (
    <div className="container">
      <div
        className="d-flex w-full justify-content-end position-fixed"
        style={{ width: "86vw" }}
      >
        <Toast
          className="d-inline-block m-1"
          bg={toastMsg.status}
          onClose={() => setToastMsg({ show: false, status: "secondary" })}
          show={toastMsg.show}
          delay={3000}
          autohide
        >
          <Toast.Body className={"text-white"}>{toastMsg.message}</Toast.Body>
        </Toast>
      </div>
      <div className="mt-5">
        <h3 className="text-center">Dashboard</h3>
        <div className="d-flex justify-content-between align-items-center">
          <InputGroup className="mb-3" style={{ maxWidth: "400px" }}>
            <FormControl
              placeholder="Search by email"
              aria-label="Search by email"
              aria-describedby="basic-addon2"
              type="email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setCurrentPage(1)}
            >
              Search
            </Button>
          </InputGroup>

          <div>
            <Button
              variant="secondary"
              className="me-2"
              onClick={() => setChangePwdPanel(true)}
            >
              Change Password
            </Button>
            <Button
              variant="primary"
              onClick={() => navigator("/admin/charges")}
            >
              View Payments
            </Button>
          </div>
        </div>
      </div>
      <div className="p-5">
        {users.length > 0 && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Email</th>
                  <th scope="col">Available Barcodes</th>
                  <th scope="col">Used Barcodes</th>
                  <th scope="col">Purchase Date</th>
                  <th scope="col">Last Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={`dashboard-tr-${index}`}>
                      <td>{(currentPage - 1) * 50 + index + 1}</td>
                      <td>{user.email}</td>
                      <td>{user.availableBarcodes}</td>
                      <td>{user.usedBarcodes}</td>
                      <td>
                        {user.purchaseDate
                          ? new Date(user.purchaseDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>
                        {user.expiryDate
                          ? new Date(user.expiryDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          title="edit user"
                          onClick={() => {
                            setEditPanel(true);
                            setEditUser(user);
                          }}
                          className="me-2"
                          style={{ cursor: "pointer" }}
                          icon={faPenToSquare}
                        />
                        <FontAwesomeIcon
                          title="View User Profile"
                          style={{ cursor: "pointer" }}
                          className="me-2"
                          onClick={() =>
                            navigator(`/user-profile/${user.email}`)
                          } // Use React Router's `useNavigate` to navigate
                          icon={faUser}
                        />
                        <FontAwesomeIcon
                          title="delete history"
                          onClick={() => handleRemoveHistoryUser(user)}
                          style={{ cursor: "pointer" }}
                          className="me-2"
                          icon={faEraser}
                        />
                        <FontAwesomeIcon
                          title="delete user"
                          onClick={() => handleRemoveUser(user)}
                          style={{ cursor: "pointer" }}
                          className="me-2"
                          icon={faTrash}
                        />
                        <FontAwesomeIcon
                          title="Update Password"
                          onClick={() => {
                            setShowUpdatePwdModal(true);
                            setEditUser(user);
                          }}
                          style={{ cursor: "pointer" }}
                          icon={faKey}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="d-flex justify-content-center">
              <div className="mt-2 me-2">
                <span>
                  Page {currentPage} of {totalPages}
                </span>
              </div>
              <div>{paginationBasic}</div>
            </div>
          </>
        )}
        {users.length === 0 && (
          <div>
            <Alert variant="danger" className="text-center">
              There is no customers!.
            </Alert>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end px-5 me-3 pb-5">
        <Button variant="primary" onClick={() => showAddModal(true)}>
          Add
        </Button>
      </div>
      <Modal
        centered
        show={addModal}
        onHide={() => showAddModal(false)}
        key={`addModal`}
      >
        <Form
          onSubmit={handleAddUser}
          style={{
            minWidth: 450,
            maxWidth: 600,
            backgroundColor: "#fff",
            padding: 30,
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder="Enter your name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              name="email"
              type="email"
              placeholder="Enter email"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              minLength={8}
              name="password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formBasicCheckbox">
            <Form.Check
              onChange={handleExpiryPanel}
              type="checkbox"
              label="Subscription"
            />
          </Form.Group>
          {expiryPanel && (
            <>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  required
                  name="amount"
                  type="number"
                  min={1}
                  max={999}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formExpiryDate">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control required name="expiryDate" type="date" />
              </Form.Group>
            </>
          )}

          <Button variant="primary" type="submit">
            Add
          </Button>
        </Form>
      </Modal>
      <Modal
        centered
        show={showEditPanel}
        onHide={() => setEditPanel(false)}
        key={`editPanel`}
      >
        <Form
          onSubmit={handleEditUser}
          style={{
            minWidth: 450,
            maxWidth: 600,
            backgroundColor: "#fff",
            padding: 30,
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              required
              name="amount"
              type="number"
              defaultValue={editUser?.amount ? editUser?.amount : 0}
              min={0}
              max={9999}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicExpiry">
            <Form.Label>Expiry Date</Form.Label>
            <Form.Control
              required
              name="expiryAt"
              type="date"
              defaultValue={editUser?.lastDate ? editUser?.lastDate : null}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Form>
      </Modal>
      <Modal
        centered
        show={showChangeModal}
        onHide={() => setChangePwdPanel(false)}
        key={`editAdminPasswordPanel`}
      >
        <Form
          onSubmit={handleChangeAdminPwd}
          style={{
            minWidth: 450,
            maxWidth: 600,
            backgroundColor: "#fff",
            padding: 30,
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              onChange={handleChangePwd}
              required
              name="pwd"
              type="password"
              min={8}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicConfirmPWD">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              onChange={handleChangePwd}
              required
              name="conPwd"
              type="password"
              min={8}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Form>
      </Modal>
      <Modal
        centered
        show={showUpdatePwdModal}
        onHide={() => setShowUpdatePwdModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update User Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowUpdatePwdModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateUserPassword}>
            Update Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
