//Profile.js

import {
  faArrowRightFromBracket,
  faArrowsRotate,
  faChartSimple,
  faGear,
  faTrash,
  faClipboard,
  faMagnifyingGlassPlus,
  faCircleInfo,
  faDownload,
  faCloudArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../utils/utils";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useAuth } from "../hooks/use-auth";
import Toast from "react-bootstrap/Toast";

export default function Profile(props) {
  const [profile, setProfile] = useState({
    barcodeLimit: 0,
    barcodeUsed: 0,
    history: [],
    expiryDate: null,
    // Other profile information as needed
  });
  const [showAlert, setPayAlert] = useState(false);
  const [showApiKey, setApiKeyModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    status: "secondary",
    show: false,
    message: "",
  });

  const { showAdmin } = props;
  const navigator = useNavigate();
  const auth = useAuth();
  const { email } = useParams(); // Grab the email parameter
  const { user, isLoading } = auth;
  const headerText = user.role === "admin" && email ? `Profile of ${email}` : `Your profile, ${user.email}`;

  const handleLogout = async () => {
    console.log("log out!");
    await auth.signOut();
    showAdmin(false);
    navigator("/login");
  };

  const handleClipText = () => {
    copy(profile?.apiKey);
  };

  const handleUpdateApiKey = async () => {
    try {
      const response = await axios.post("/api/profile/update/key");
      setProfile({
        ...profile,
        apiKey: response.data.apiKey,
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to update key!",
      });
      console.log("error");
    }
  };

  const handleUpdateLink = async () => {
    try {
      const response = await axios.post("/api/profile/update/link");
      setProfile({
        ...profile,
        link: response.data.link,
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to update link!",
      });
      console.log("error");
    }
  };

  const handleDeleteHistory = async (item) => {
    try {
      const response = await axios.post("/api/profile/history/delete", {
        item,
      });
      if (item) {
        setProfile({
          ...profile,
          history: profile.history.filter(
            (row) => row.createdAt !== item.createdAt
          ),
        });
      } else {
        setProfile({
          ...profile,
          history: [],
        });
      }
      setToastMsg({
        status: "success",
        show: true,
        message: "A user's history has been removed!",
      });
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to remove history!",
      });
      console.log("error");
    }
    setApiKeyModal(false);
  };

  const handleDownloadFile = async (item, type) => {
    try {
      // Create a download link
      const response = await axios.post(
        "/api/history/download",
        { filename: item.file_name },
        { responseType: "blob" }
      );
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(new Blob([response.data]));
      const filename = item.file_name.split(".")[0];
      downloadLink.download = filename + `.${type}`;
      // downloadLink.target = '_blank'

      // Trigger a click event to initiate download
      downloadLink.click();
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to download image!",
      });
      // console.log('error', err)
    }
  };

  const handleOpenPreview = async (item) => {
    try {
      setShowPreview(true);
      const response = await axios.post(
        "/api/history/download",
        { filename: item.file_name },
        { responseType: "blob" }
      );
      document.querySelector("#previewDesc").textContent = item.data;
      let image = new Image();
      let canvas = document.createElement("canvas");
      canvas.width = 500;
      canvas.height = 120;
      let context = canvas.getContext("2d");
      // Load image when it's fully loaded
      document.getElementById("canvasDiv").appendChild(canvas);
      image.onload = () => {
        // Draw the image on the canvas
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
      image.src = URL.createObjectURL(new Blob([response.data]));
    } catch (err) {
      setToastMsg({
        status: "danger",
        show: true,
        message: "Failed to open preview!",
      });
      console.log("error", err);
    }
  };

  useEffect(() => {
    // Determine the email to fetch: if the user is an admin and an email param exists, use it; otherwise, use the logged-in user's email.
    const fetchEmail = user.role === "admin" && email ? email : user.email;
    // Fetch the profile using fetchEmail
  }, [user, email, isLoading]);

  useEffect(() => {
    const fetchProfileInfo = async () => {
      if (!auth.isLoading && auth.isAuthenticated) {
        const profileUrl =
          user.role === "admin" && email
            ? `/api/profile/all?email=${email}`
            : "/api/profile/all";
        try {
          const response = await axios.post(profileUrl);
          setProfile({
            ...profile,
            barcodeLimit: response.data.barcodeLimit,
            barcodeUsed: response.data.barcodeUsed,
            expiryDate: response.data.expiryDate,
            history: response.data.history,
          });
          // Adjust showAlert based on the limit and used barcodes
          const currentDate = new Date();
          const expiryDate = new Date(response.data.expiryDate);
          const hasExpiryDatePassed = expiryDate < currentDate;

          // Determine if the used barcodes equal or exceed the limit
          const hasUsedAllBarcodes =
            response.data.barcodeUsed >= response.data.barcodeLimit;

          // Set showAlert based on the limit, used barcodes, and expiry date
          setPayAlert(hasUsedAllBarcodes || hasExpiryDatePassed);
        } catch (err) {
          if (err.response && err.response.status === 403) {
            setToastMsg({
              status: "danger",
              show: true,
              message: "You should log in again!",
            });
            navigator("/login");
          } else if (err.response && err.response.status === 454) {
            setPayAlert(true);
          }
        }
      }
    };

    fetchProfileInfo();
  }, [auth.isLoading, auth.isAuthenticated, props.navigator]); // Add auth.isLoading and auth.isAuthenticated as dependencies

  return (
    <div className="container">
      <div
        className="d-flex w-full justify-content-end position-fixed"
        style={{ width: "86vw" }}
      >
        <Toast
          className="d-inline-block m-1"
          bg={toastMsg.status}
          onClose={() => setToastMsg({ show: false, status: "secondary" })}
          show={toastMsg.show}
          delay={3000}
          autohide
        >
          <Toast.Body className={"text-white"}>{toastMsg.message}</Toast.Body>
        </Toast>
      </div>
      <div className="container">
        <div>
          <h3 className="text-center my-3">
            {headerText}
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Sign out</Tooltip>}
            >
              <span
                className="ms-3"
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </span>
            </OverlayTrigger>
          </h3>
        </div>
        <Alert show={showAlert} className="text-center" variant="danger">
          You don't have barcodes. Please{" "}
          <Link to={`/pricing`} onClick={() => navigator("pricing")}>
            buy barcode packages
          </Link>{" "}
          to get access to generators.
        </Alert>
        <div className="border rounded-2 py-2 px-4">
          <Row>
            <Col md={4}>
              <div>
                {`Available Barcodes: `}
                <span className="text-success">
                  {profile.barcodeLimit - profile.barcodeUsed}
                </span>
              </div>
            </Col>
            <Col md={4}>
              <div>
                {`Used Barcodes: `}
                <span className="text-danger">{profile.barcodeUsed}</span>
              </div>
            </Col>
            <Col md={4}>
              <div>
                {`Expiry Date: `}
                <span
                  className={
                    new Date(profile.expiryDate) < new Date()
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {profile.expiryDate
                    ? new Date(profile.expiryDate).toLocaleDateString()
                    : "N/A"}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-2 mb-4">
          <Row>
            <Col md={6}>
              <div className="d-grid">
                <Button
                  variant="outline-secondary"
                  onClick={() => setApiKeyModal(true)}
                >
                  <FontAwesomeIcon className="me-2" icon={faGear} />
                  settings
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-grid">
                <Button variant="outline-secondary">
                  <FontAwesomeIcon className="me-2" icon={faChartSimple} />
                  Refferral program
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-2 mb-4">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Item</th>
                <th scope="col">Data</th>
                <th scope="col">File</th>
              </tr>
            </thead>
            <tbody>
              {profile?.history.map((item, idx) => {
                return (
                  <tr key={`barcode-history-row-${idx}`}>
                    <td>{item.createdAt}</td>
                    <td>{item.item}</td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{item.data}</Tooltip>}
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>preview</Tooltip>}
                      >
                        <FontAwesomeIcon
                          onClick={() => handleOpenPreview(item)}
                          className="me-2"
                          icon={faMagnifyingGlassPlus}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>download as png</Tooltip>}
                      >
                        <FontAwesomeIcon
                          onClick={() => handleDownloadFile(item, "png")}
                          className="me-2"
                          icon={faCloudArrowDown}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>download as jpg</Tooltip>}
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          onClick={() => handleDownloadFile(item, "jpg")}
                          icon={faCloudArrowDown}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>delete</Tooltip>}
                      >
                        <FontAwesomeIcon
                          onClick={() => handleDeleteHistory(item)}
                          className="me-2"
                          icon={faTrash}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        centered
        size="lg"
        show={showApiKey}
        onHide={() => setApiKeyModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 15 }}>
          <div className="d-flex justify-content-between align-items-center">
            <span>
              {`API Key: ${profile?.apiKey}`}
              <OverlayTrigger overlay={<Tooltip>Copy to clipboard</Tooltip>}>
                <button className="border-0 ms-2" onClick={handleClipText}>
                  <FontAwesomeIcon icon={faClipboard} />
                </button>
              </OverlayTrigger>
            </span>
            <div>
              <Badge
                variant="primary"
                style={{ cursor: "pointer", width: 80 }}
                onClick={handleUpdateApiKey}
              >
                Update
                <FontAwesomeIcon className="ms-2" icon={faArrowsRotate} />
              </Badge>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div style={{ minWidth: 100, width: 150 }}>Referral link: </div>
              <Form.Control
                type="text"
                placeholder={profile?.link}
              ></Form.Control>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    The allowed length is 5-12 characters. Leave the field empty
                    and click <b>Update</b> button if you want to generate a
                    random link
                  </Tooltip>
                }
              >
                <FontAwesomeIcon className="ms-2" icon={faCircleInfo} />
              </OverlayTrigger>
            </div>
            <div>
              <Badge
                variant="primary"
                style={{ cursor: "pointer", width: 80 }}
                onClick={handleUpdateLink}
              >
                Update
                <FontAwesomeIcon className="ms-2" icon={faArrowsRotate} />
              </Badge>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <div>Clear my order history</div>
            <div>
              <Badge
                variant="primary"
                style={{ cursor: "pointer", width: 80 }}
                onClick={() => handleDeleteHistory(null)}
              >
                Delete
                <FontAwesomeIcon className="ms-2" icon={faTrash} />
              </Badge>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="md"
        show={showPreview}
        onHide={() => setShowPreview(false)}
      >
        <div id="canvasDiv">
          <div>
            <p id="previewDesc" className="fw-bold px-3 py-2"></p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
