//utils.js

import axios from "axios";
import { serverUrl } from "../config";

const api = axios.create({
  baseURL: "/", // for local
  // baseURL: serverUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const storedData = JSON.parse(localStorage.getItem("barcode_user"));
    if (storedData && storedData.token) {
      config.headers.Authorization = storedData.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      const errorMessage = error.response.data.msg;
      // Look for specific error message indicating token issues
      if (errorMessage && (errorMessage.includes("Authentication token not provided or invalid.") || errorMessage.includes("Token is invalid or expired."))) {
        console.log("Token expired or auth error, logging out...");
        localStorage.removeItem("barcode_user");
        window.location = "/login"; // Redirect to login page
      }
    }
    return Promise.reject(error);
  }
);


export default api;

export const regions = [
  {
    country: "USA states",
    regions: [
      { label: "Alabama", code: "AL", revDate: "11012014" },
      { label: "Alaska", code: "AK", revDate: "11012018" },
      { label: "Arizona", code: "AZ", revDate: "02142014" },
      { label: "Arkansas", code: "AR", revDate: "03012018" },
      { label: "California (2017)", code: "CA", revDate: "08292017" },
      {
        region: "Colorado",
        items: [
          { label: "Colorado (2015)", code: "CO", revDate: "10302015" },
          { label: "Colorado (2022)", code: "CO", revDate: "03012022" },
        ],
      },
      { label: "Connecticut", code: "CT", revDate: "02102017" },
      { label: "Delaware", code: "DE", revDate: "08022017" },
      { label: "Florida", code: "FL", revDate: "03162017" },
      {
        region: "Georgia",
        items: [
          { label: "Georgia (2015)", code: "GA", revDate: "07012015" },
          { label: "Georgia (2019)", code: "GA", revDate: "01022019" },
        ],
      },
      { label: "Hawaii", code: "HI", revDate: "" },
      { label: "Idaho", code: "ID", revDate: "09052016" },
      { label: "Illinois", code: "IL", revDate: "09172015" },
      { label: "Indiana", code: "IN", revDate: "07242018" },
      {
        region: "Iowa",
        items: [
          { label: "Iowa (2011)", code: "IA", revDate: "07252011" },
          { label: "Iowa (2017)", code: "IA", revDate: "06292017" },
        ],
      },
      { label: "Kansas", code: "KS", revDate: "02242017" },
      {
        region: "Kentucky",
        items: [
          { label: "Kentucky (2012)", code: "KY", revDate: "03162012" },
          { label: "Kentucky (2018)", code: "KY", revDate: "08312018" },
        ],
      },
      { label: "Louisiana", code: "LA", revDate: "02102015" },
      { label: "Maine", code: "ME", revDate: "01042019" },
      { label: "Maryland", code: "MD", revDate: "08262019" },
      { label: "Massachusetts", code: "MA", revDate: "02222016" },
      { label: "Michigan", code: "MI", revDate: "01212011" },
      { label: "Minnesota", code: "MN", revDate: "10232017" },
      { label: "Mississippi", code: "MS", revDate: "02222016" },
      {
        region: "Missouri",
        items: [
          { label: "Missouri (2012)", code: "MO", revDate: "12102012" },
          { label: "Missouri (2020)", code: "MO", revDate: "01272020" },
        ],
      },
      { label: "Montana", code: "MT", revDate: "10072015" },
      { label: "Nebraska (2017)", code: "NE", revDate: "02282017" },
      { label: "Nevada", code: "NV", revDate: "01172018" },
      { label: "New Hampshire", code: "NH", revDate: "06092016" },
      { label: "New Jersey (2020)", code: "NJ", revDate: "01082020" },
      { label: "New Mexico", code: "NM", revDate: "08232019" },
      {
        region: "New York",
        items: [
          { label: "New York (2017)", code: "NY", revDate: "09282017" },
          { label: "New York (2022)", code: "NY", revDate: "03072022" },
        ],
      },
      { label: "North Carolina", code: "NC", revDate: "10242014" },
      { label: "North Dakota", code: "ND", revDate: "01082014" },
      { label: "Ohio", code: "OH", revDate: "07012018" },
      { label: "Oklahoma", code: "OK", revDate: "01132020" },
      { label: "Oregon", code: "OR", revDate: "07092018" },
      { label: "Pennsylvania", code: "PA", revDate: "06072016" },
      { label: "Rhode Island", code: "RI", revDate: "01262016" },
      { label: "South Carolina", code: "SC", revDate: "01012018" },
      { label: "South Dakota", code: "SD", revDate: "06292017" },
      { label: "Tennessee", code: "TN", revDate: "12022011" },
      {
        region: "Texas",
        items: [
          { label: "Texas (2016)", code: "TX", revDate: "10102016" },
          { label: "Texas (2020)", code: "TX", revDate: "02232020" },
        ],
      },
      { label: "Utah", code: "UT", revDate: "04152016" },
      { label: "Vermont", code: "VT", revDate: "10162018" },
      { label: "Virginia", code: "VA", revDate: "12102008" },
      { label: "Washington", code: "WA", revDate: "11122019" },
      { label: "Washington DC", code: "WA", revDate: "" },
      { label: "West Virginia", code: "WV", revDate: "06042019" },
      { label: "Wisconsin", code: "WI", revDate: "09012015" },
      { label: "Wyoming", code: "WY", revDate: "11142018" },
    ],
  },
  {
    country: "Canada",
    regions: [
      { label: "Alberta", code: "AB" },
      { label: "British Columbia", code: "BC" },
      { label: "Manitoba", code: "MB" },
      { label: "New Brunswick", code: "NB" },
      { label: "Newfoundland and Labrador", code: "NF" },
      { label: "Northwest Territories", code: "NT" },
      { label: "Nova Scotia", code: "NS" },
      { label: "Nunavut", code: "NU" },
      { label: "Ontario", code: "ON" },
      { label: "Prince Edward Island", code: "PE" },
      { label: "Quebec", code: "QC", revDate: "20150801" },
      { label: "Saskatchewan", code: "SK" },
      { label: "The Yukon", code: "YT" },
    ],
  },
];

export const states = [
  {
    country: "USA states",
    payload: [
      {
        header: {
          title: "Alabama",
          rev: "Rev. 11/01/2014",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "7253231",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "3704 3RD PL NE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "3704 3RD PL NE",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Center Point",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Center Point",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "352151400",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "352151400",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262021",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: null,
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "00000000002953983401",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "up to 31 kg (up to 70 lbs)", val: "DCE0" },
              { label: "32 – 45 kg (71 – 100 lbs)", val: "DCE1" },
              { label: "46 - 59 kg (101 – 130 lbs)", val: "DCE2" },
              { label: "60 - 70 kg (131 – 160 lbs)", val: "DCE3" },
              { label: "71 - 86 kg (161 – 190 lbs)", val: "DCE4" },
              { label: "87 - 100 kg (191 – 220 lbs)", val: "DCE5" },
              { label: "101 - 113 kg (221 – 250 lbs)", val: "DCE6" },
              { label: "114 - 127 kg (251 – 280 lbs)", val: "DCE7" },
              { label: "128 – 145 kg (281 – 320 lbs)", val: "DCE8" },
              { label: "146+ kg (321+ lbs)", val: "DCE9" },
            ],
            name: "weight",
            label: "Weight Range",
            tip: null,
            auto: null,
            style: {
              placeholder: " -- Select -- ",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "up to 31 kg (up to 70 lbs)", val: "DCE0" },
            feedback: "Please select an eye color from the list.",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Alaska",
          rev: "Rev. 11/01/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: " You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "7476172",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "12950 Mission Cir",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "12950 Mission Cir",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Anchorage",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Anchorage",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "99516",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "99516",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "10 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "1000728920",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "7 digits + whitespace + 3 ditis + issue date (YYMMDD) + 3 letters + -1 or -0. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "8475992 460200826PNV-1",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Arizona",
          rev: "Rev. 02/14/2014",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Letter + 8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D28375514",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "4248 S Red Rock St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "4248 S Red Rock St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Gilbert",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Gilbert",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "85297",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "85297",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262016",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152035",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "16361AZ0068743540301",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "5641GS561D7977J0",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Arkansas",
          rev: "Rev. 03/01/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "'9' + 8 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D28375514",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "707 Fairway Cir",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "707 Fairway Cir",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Springdale",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Springdale",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "727641027",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "727641027",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0210119836604160",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "212232102 2160",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "California (2017)",
          rev: "Rev. 08/29/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D2837551",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "516 Anson Ct",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "516 Anson Ct",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Ronhert Park",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Ronhert Park",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "875420000",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "875420000",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262021",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },

          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "First 2 digits is issue year. Next 3 digits is number of days from the beginning of the year to document. Next numbers is document number + 0401. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "L93259228",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "First 8 digits is issue date in DD/MM/YYYY format. The last 2 digits is expiry year. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "889287786",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRN (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (as on DL)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRN (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (as on DL)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRN (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRN (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRN (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BRN (Brown)", val: "BRO" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Colorado (2015)",
          rev: "Rev. 10/30/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "9 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "316168738",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "435 N 35th Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "435 N 35th Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Greeley",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Greeley",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "80634",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "80634",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "R",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "R",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152023",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "Audit info",
            tip: "CODL_0_ + Date of issue + 1 day in MMDDYY format + '_' + 5 random digits. You can click on the calculator icon to calculate data <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "CODL_0_082720_01876",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "7 random digits <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "6800779",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Colorado (2022)",
          rev: "Rev. 10/30/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "316168738",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "435 N 35th Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "435 N 35th Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Greeley",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Greeley",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "80634",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "80634",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "R",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "R",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212023",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "Audit info",
            tip: "CODL_0_ + Date of issue + 1 day in MMDDYY format + '_' + 5 random digits. You can click on the calculator icon to calculate data <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "CODL_0_082720_01876",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "7 random digits <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "5211068",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Connecticut",
          rev: "Rev. 02/10/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "316168738",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "435 N 35th Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "435 N 35th Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Greeley",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Greeley",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "80634",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "80634",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "R ",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152023",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "DL Number + 'CT' + random digit + 4 random letters + '01'. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "137010702CT3ENV01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "YYMMDD (Issue date) + 6 digits + 01MV + 2 character. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "20082614021501MVS1",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Delaware",
          rev: "Rev. 08/02/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "316168738",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "435 N 35th Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "435 N 35th Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Greeley",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Greeley",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "80634",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "80634",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "R ",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152023",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "0110 + 12 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0110157073938301",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "L + issue datae (YYYYMMDD) + 6 digits + letter. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "L20200826649034L",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Yes", val: "F" },
              { label: "No", val: "N" },
            ],
            name: "realID",
            label: "Is REAL ID",
            tip: "REAL ID is a federal law that affects how states issue driver's licenses and ID cards if they are going to be acceptable for federal purposes, such as boarding a domestic commercial flight, or entering a military installation or federal facility that requires ID.",
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "Yes", val: "F" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Florida",
          rev: "Rev. 07/01/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Random letter + 12 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "I400461755656",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "435 N 35th Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "435 N 35th Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Homestead",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Homestead",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "330325131",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "330325131",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152023",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "<br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "N322108265187",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "0100 + 12 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0100385619573853",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "03/16/2017", val: "03162017" },
              { label: "05/01/2019", val: "05012019" },
              { label: "03/01/2020", val: "03012020" },
            ],
            name: "rev_date",
            label: "Revision date",
            tip: null,
            auto: null,
            style: {
              placeholder: "-- select --",
              width: {
                md: 4,
                sm: 6,
              },
            },
            default: { label: "03/16/2017", val: "03162017" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Georgia (2015)",
          rev: "Rev. 07/01/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "9 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "089007583",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "5485 Copperfield Ct NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "5485 Copperfield Ct NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Lilburn",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Lilburn",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "300475809",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "300475809",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format (07/01/2015 till 01/02/2019)",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262016",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "www",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "first 14 digits of ICN + 4 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "211942358410046350",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "10 random digits + '100401'. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "2119423584100401",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Please enter a weight",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "country",
            label: "Country",
            tip: null,
            auto: null,
            style: {
              placeholder: "Gwinnett",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "Gwinnett",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Georgia (2019)",
          rev: "Rev. 01/02/2019",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "9 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "089007583",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "5485 Copperfield Ct NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "5485 Copperfield Ct NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Lilburn",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Lilburn",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "30047-5809",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "30047-5809",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "www",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "11 random digits + '00' + 5 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "026568004260034885",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "1000 + 7 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "10001308391",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Please enter a weight",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "country",
            label: "Country",
            tip: null,
            auto: null,
            style: {
              placeholder: "Gwinnett",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "Gwinnett",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Hawaii",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "H + 8 digts. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "H + 8 digts",
            style: {
              placeholder: "H00716258",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1413 Hoohulu St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1413 Hoohulu St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Pearl City",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Pearl City",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "96782",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "96782",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "3",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "3",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "Audit Info",
            tip: "Issue date + 6 days (YYYYMMDD) + _053001_2_ + digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Issue date + 6 days (YYYYMMDD) + _053001_2_ + digits",
            style: {
              placeholder: "20200902_053001_2_690",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Issue date (YYYYMMDD) + digts and letters. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "Issue date (YYYYMMDD) + digts and letters",
            style: {
              placeholder: "20200826662548377772-ICWH",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Idaho",
          rev: "Rev. 09/05/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "2 letters + six digits + letter. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "2 letters + six digits + letter",
            style: {
              placeholder: "DX121380D",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1341 W Ann Taylor St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1341 W Ann Taylor St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Meridian",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Meridian",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "836464074",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "836464074",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "000' + 7 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "000 + 7 digits",
            style: {
              placeholder: "0001700735",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "010170050042",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Illinois",
          rev: "Rev. 09/17/2015",
        },
        items: [
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "First digit of the Last Name + 11 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "First digit of the Last Name + 11 random digits, only need 7 digits",
            style: {
              // maxLength: 8,
              placeholder: "D83877907578",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "5620 Pebblebeach Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "5620 Pebblebeach Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Hanover Park",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Hanover Park",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "60133",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "60133",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262021",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "First 8 digits is issue date in YYYYMMDD format. The last 3 random digits + 2 random letters + 4 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "First 8 digits is issue date in YYYYMMDD format",
            style: {
              placeholder: "20210826494MG4931",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "First 12 characters is Document Number. Random characters + 01. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "First 12 characters is Document Number. Random characters + 01",
            style: {
              placeholder: "D83877907578ILO9TL01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              min: 30,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "JR (Junior)", val: "JR" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Indiana",
          rev: "Rev. 07/24/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "4 digits - 2 digits - 4 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "4 digits - 2 digits - 4 digits.",
            style: {
              placeholder: "2739-94-6144",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "144 Pine View Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "144 Pine View Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Carmel",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Carmel",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "46032",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "46032",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issueOffice",
            label: "Issuing Office",
            tip: "3 digit issuing office code (located under the photo)",
            auto: null,
            style: {
              placeholder: "527",
              maxLength: 3,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a issue office.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "03701 + 11 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "03701 + 11 digits",
            style: {
              placeholder: "0370101906831627",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Issue date in MMDDYY format + DL issuing office code (Located under the photo) + 00 + 3 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "Issue date in MMDDYY format + DL issuing office code (Located under the photo) + 00 + 3 digits.",
            style: {
              placeholder: "08262081000337",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRN (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (as on DL)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRN (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (as on DL)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRN (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Iowa (2011)",
          rev: "Rev. 07/25/2011",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "3 digits + 2 letters + 4 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "3 digits + 2 letters + 4 digits",
            style: {
              placeholder: "176ZB6504",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "7779 Cottonwood Ln",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "7779 Cottonwood Ln",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "West Des Moines",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "West Des Moines",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "50266",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "50266",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212016",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + issue year + number of days from the beginning of year (+1 day) + 0101. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "176ZB6504162400101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "9 digits + first letter of last name + first letter of first name + 4 digits + sex + expiry date (MMDDYY) + R. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "173604035DJ6050M150124R",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Yes", val: "F" },
              { label: "No", val: "N" },
            ],
            name: "real",
            label: "Is REAL ID",
            tip: null,
            auto: null,
            style: {
              placeholder: "Yes",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "Yes", val: "F" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Iowa (2017)",
          rev: "Rev. 06/29/2017 (0900)",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "176ZB6504",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "810 Bay Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "810 Bay Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Ames",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Ames",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "50010",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "50010",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "Audit info",
            tip: "Document number + issue year + number of days from the beginning of year (+3 day) + 0101. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Document number + issue year + number of days from the beginning of year (+3 day) + 0101",
            style: {
              placeholder: "176ZB6504162400101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "9 digits + first letter of last name + first letter of first name + 4 digits + sex + expiry date (MMDDYY) + R. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "173604035DJ6050M150124D",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Kansas",
          rev: "Rev. 02/24/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: " You can click on the calculator icon to calculate number <b>automatically!</b> You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "dl number like K00-45-2697",
            style: {
              placeholder: "K00-45-2697",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "12727 W 100th St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "12727 W 100th St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Lenexa",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Lenexa",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "66215",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "66215",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. Validity 6 years for 21-64 years old. If age is more than 64 years or less than 21 - the validity period is 4 years",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "20242K004526970101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "72405466640DJ20240M2615AB",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              min: 30,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Kentucky (2012)",
          rev: "Rev. 03/16/2012",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "First letter of the Laste name + 8 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D82767580",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "535 Ashcreek Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "535 Ashcreek Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Paducah",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Paducah",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "42001",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "42001",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212017",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "04600 + year of issue + 9 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0460017830322351",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "16 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "4372782319947690",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Kentucky (2018)",
          rev: "Rev. 08/31/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "First letter of the Laste name + 8 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D82767580",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "535 Ashcreek Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "535 Ashcreek Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Paducah",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Paducah",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "42001",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "42001",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212017",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "04600 + year of issue + 9 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0460017830322351",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "16 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "4372782319947690 01111",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Yes", val: "F" },
              { label: "No", val: "N" },
            ],
            name: "realID",
            label: "Is REAL ID",
            tip: "REAL ID is a federal law that affects how states issue driver's licenses and ID cards if they are going to be acceptable for federal purposes, such as boarding a domestic commercial flight, or entering a military installation or federal facility that requires ID.",
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "Yes", val: "F" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Louisiana",
          rev: "Rev. 02/10/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "00 or 01 + 7 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "00 or 01 + 7 digits",
            style: {
              placeholder: "007343439",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1016 Sewell St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1016 Sewell St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Slidell",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Slidell",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "70461",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "70461",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "E",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "Audit info",
            tip: "4 digits. You can click on the calculator icon to calculate data <b>automatically!</b>",
            auto: "4 digits",
            style: {
              placeholder: "4174",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "00700 + 11 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0070031972215065",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0070031972215065",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
          {
            type: "text",
            name: "issieOffice",
            label: "Issuing Office",
            tip: "3 digit issuing office code (located under the photo)",
            auto: "3 digit issuing office code",
            style: {
              placeholder: "033",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "parish",
            label: "Parish",
            tip: null,
            auto: null,
            style: {
              placeholder: "52",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "52",
            feedback: "Enter a valid value.",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Maine",
          rev: "Rev. 01/04/2019",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "9278451",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "52 Gardner St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "52 Gardner St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Patten",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Patten",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "04765",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "04765",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "11",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "F2024392784510101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "17 zeros + 8 digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "17 zeros + 8 digits",
            style: {
              placeholder: "0000000000000000035222862",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Maryland",
          rev: "Rev. 06/20/2016 & 08/26/2019",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "First letter of Last name + 12 digits You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D-000-429-000-042",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "2125 Haven Oak Ct",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "2125 Haven Oak Ct",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Abingdon",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Abingdon",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "21009",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "21009",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262016",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'100' + 7 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "100' + 7 digits",
            style: {
              placeholder: "1005403079",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "9 digits and letters. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "9 digits and letters",
            style: {
              placeholder: "106143DH0",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "06/20/2016", val: "06202016" },
              { label: "08/26/2019", val: "08262019" },
            ],
            name: "rev_date",
            label: "Revision date",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "06/20/2016", val: "06202016" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Massachusetts",
          rev: "Rev. 02/22/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "S + 8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "S + 8 random digits",
            style: {
              placeholder: "S94506149",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "105 New Estate Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "105 New Estate Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Littleton",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Littleton",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "014601131",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "014601131",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "20239S945061490601",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Issue date + revision date <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "Issue date + revision date",
            style: {
              placeholder: "08262020 REV 02222016",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Michigan",
          rev: "Rev. 01/21/2011",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: " You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate DL number of Michigan",
            style: {
              placeholder: "D 000 429 497 042",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1040 Southlawn Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1040 Southlawn Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "East Lansing",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "East Lansing",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "488233041",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "488233041",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "0",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "0",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 4 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + birth date (YYYYMMDD) + issue year (YYYY). You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Document number + birth date (YYYYMMDD) + issue year (YYYY)",
            style: {
              placeholder: "D000429497042197001152024",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Minnesota",
          rev: "Rev. 10/23/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "One letter and 12 numbers. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "One letter and 12 numbers",
            style: {
              placeholder: "T403223261033",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "4102 Beaver Dam Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "4102 Beaver Dam Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Eagan",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Eagan",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "551222123",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "551222123",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 4 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + '01' + number of days from the beginning of year to issue date + '01'. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Document number + '01' + number of days from the beginning of year to issue date + '01'",
            style: {
              placeholder: "T4032232610330124301",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "7 zeros + 7 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "7 zeros + 7 random digits.",
            style: {
              placeholder: "00000006518117",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRN (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (as on DL)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Mississippi",
          rev: "Rev. 02/22/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "'80' + 7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "'80' + 7 random digits",
            style: {
              placeholder: "809430018",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "7504 Oak Cir W",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "7504 Oak Cir W",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Ocean Springs",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Ocean Springs",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "39564",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "39564",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "R",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "R",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 4 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'05100' + 11 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "0510035326620190",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: " <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "7TMLJG3R10DJ20240M2415A",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Missouri (2012)",
          rev: "Rev. 12/10/2012",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "letter + 9 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "letter + 9 digits",
            style: {
              placeholder: "S160316442",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "132 Cranberry R",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "132 Cranberry R",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Billings",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Billings",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "65610",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "65610",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "F",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "F",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "First 2 digtis is issue year (YY), next 3 digits is number of days from issue date + 1. Then document number and '0101. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "18239S1603164420101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "First 2 digits is issue year (YY), next 3 digits is first digits of document number, next 3 digits is number of days from issue date,  then '00' + 2 random digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "181602380083",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Missouri (2020)",
          rev: "Rev. 01/27/2020",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "letter + 9 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "M096115590",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "132 Cranberry R",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "132 Cranberry R",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Billings",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Billings",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "65610",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "65610",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "E",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "First 2 digits is issue year (YY) + '14' + 4 digits + '00' + 2 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "201447280083",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "First 2 digtis is issue year (YY), next 3 digits is number of days from issue date + 1. Then document number and '0101. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "18239S1603164420101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Yes", val: "F" },
              { label: "No", val: "N" },
            ],
            name: "realID",
            label: "Is REAL ID",
            tip: "REAL ID is a federal law that affects how states issue driver's licenses and ID cards if they are going to be acceptable for federal purposes, such as boarding a domestic commercial flight, or entering a military installation or federal facility that requires ID.",
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "Yes", val: "F" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Montana",
          rev: "Rev. 10/07/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "birth month + 3 digits + birth year + '41' + birth day. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "0173719704151",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "665 5th Ave W N",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "665 5th Ave W N",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Kalispell",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Kalispell",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "59901",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "59901",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "110 + 9 digits and letters + MT + 3 letters + 001. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "110046MJU9PFMTLLC001",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Issue data in YYYYMMDD format + 12 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "20200826869633619021",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 2,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Nevada",
          rev: "Rev. 01/17/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "10 random digits and letter. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "1314295172",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First & Middle name",
            tip: null,
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 6,
                sm: 6,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "9833 Babys Breath Ct",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "9833 Babys Breath Ct",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Las Vegas",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Las Vegas",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "891837132",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "891837132",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "null",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },

          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'001' + 8 random digits + '01'.You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0015969352901",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "'0001' + 17 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "000144360705221148270",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Please enter a weight",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "",
            feedback: "Please enter a weight",
          },
        ],
      },
      {
        header: {
          title: "New Hampshire",
          rev: "Rev. 06/09/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "'NHL' + 8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "NHL10501102",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "102 Center Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "102 Center Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Weare",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Weare",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "03281",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "03281",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "qq",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },

          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "08494818",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "08494818",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "New Jersey (2020)",
          rev: "Rev. 01/08/2020",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "First letter of Last name + 14 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "H + 8 digts",
            style: {
              placeholder: "D26229134787273",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "5 Willow Spring Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "5 Willow Spring Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Morristown",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Morristown",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "079602832",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "079602832",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "First letter of Last name + 13 digits and letters + '8M01'. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Issue date + 6 days (YYYYMMDD) + _053001_2_ + digits",
            style: {
              placeholder: "DWCDQP3F98YNLD8M01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Option 1 + issue year (YYYY) + number of days from the beginnig of year to issue date + '0000' + 4 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "Issue date (YYYYMMDD) + digts and letters",
            style: {
              placeholder: "WV202023900007318",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (as on DL)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BRO (Brown)", val: "BRO" },
            required: true,
          },
          {
            type: "text",
            name: "option1",
            label: "Option 1",
            tip: "On the front side of DL (the lowest row of data)",
            auto: null,
            style: {
              placeholder: "WV",
              min: 30,
              width: {
                md: 4,
                sm: 3,
              },
            },
            default: "WV",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "text",
            name: "option2",
            label: "Option 2",
            tip: "On the front side of DL (the lowest row of data)",
            auto: null,
            style: {
              placeholder: "REN",
              min: 30,
              width: {
                md: 4,
                sm: 3,
              },
            },
            default: "REN",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "text",
            name: "option3",
            label: "Option 3",
            tip: "On the front side of DL (the lowest row of data)",
            auto: null,
            style: {
              placeholder: "24.00",
              min: 30,
              width: {
                md: 4,
                sm: 3,
              },
            },
            default: "24.00",
            feedback: "Enter a valid value",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "New Mexico",
          rev: "Rev. 08/23/2019",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "9 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "2 letters + six digits + letter",
            style: {
              placeholder: "036408091",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "10801 Hagen Rd NE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "10801 Hagen Rd NE",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Albuquerque",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Albuquerque",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "87111",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "87111",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: null,
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + 01. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "000 + 7 digits",
            style: {
              placeholder: "03640809101",
              width: {
                md: 6,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "New York (2017)",
          rev: "Rev. 09/28/2017 (0303)",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "759547815",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First & Middle name",
            tip: "Enter First name or First + Middle separated by commas.",
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "Donald,James",
              width: {
                md: 6,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "710 Hubbell Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "710 Hubbell Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Churchville",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Churchville",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "14428",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "14428",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "10 random characters. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "First 8 digits is issue date in YYYYMMDD format",
            style: {
              placeholder: "C6JEH2RP06",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "New York (2022)",
          rev: "Rev. 03/07/2022 (1004)",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "759547815",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "710 Hubbell Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "710 Hubbell Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Churchville",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Churchville",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "14428",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "14428",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "10 random characters. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "First 8 digits is issue date in YYYYMMDD format",
            style: {
              placeholder: "C6JEH2RP06",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "North Carolina",
          rev: "Rev. 10/24/2014",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "5 zeros + 7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "3 digits + 2 letters + 4 digits",
            style: {
              placeholder: "000007886015",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "110 Lemons St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "110 Lemons St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Stoneville",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Stoneville",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "270488702",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "270488702",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212016",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "000007886015NCXR9S01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Document number + audit information <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "0015245648",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },

          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Alaskan or American Indian", val: "AI" },
              { label: "Asian or Pacific Islander", val: "AP" },
              { label: "Black", val: "BK" },
              { label: "Hispanic Origin", val: "H" },
              { label: "Non-hispanic", val: "O" },
              { label: "Unknown", val: "U" },
              { label: "White", val: "W" },
            ],
            name: "race",
            label: "Race (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "Unknown", val: "U" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: { label: "BRO (Brown)", val: "BRO" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: { label: "BRO (Brown)", val: "BRO" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "North Dakota",
          rev: "Rev. 01/08/2014",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: ". You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "DOE707980",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "815 13th Ave W",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "815 13th Ave W",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Williston",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Williston",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "58801",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "58801",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'03400' + 11 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Document number + issue year + number of days from the beginning of year (+3 day) + 0101",
            style: {
              placeholder: "0340018171565059",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Decade of birth year + DL Number + Last and First name initials + 4 digits + lowecase and uppercase letter + 2 digits + 'NDZ'. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "7DOE707980DJ78517vP48NDZ",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "text",
            name: "option1",
            label: "Option",
            tip: "On the front side of DL (the lowest row of data)",
            auto: null,
            style: {
              placeholder: "182",
              min: 30,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "182",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 12,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Ohio",
          rev: "Rev. 07/01/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Two letters and six numbers. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "dl number like KD298593",
            style: {
              placeholder: "KD298593",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1101 W Forest Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1101 W Forest Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Lakewood",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Lakewood",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "44107",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "44107",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "random letter + 8 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "L93259228",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "9 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "889287786",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              min: 30,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
          {
            type: "text",
            name: "Place pob",
            label: "Place of birth",
            tip: "Country and municipality and/or state/province",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              maxLength: 8,
              placeholder: "Ohio",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Oklahoma",
          rev: "Rev. 01/13/2020",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Letter + 9 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "S576189157",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1927 N Broadway Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1927 N Broadway Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Shawnee",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Shawnee",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "748043721",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "748043721",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + OK + 4 letters + 01. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "S576189157OKYRSL01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Documunet number + Date of birth (MMDDYY) + issue date (MMDDYY) + letter. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "S576189157011570082620R",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Yes", val: "F" },
              { label: "No", val: "N" },
            ],
            name: "realID",
            label: "Is REAL ID",
            tip: "REAL ID is a federal law that affects how states issue driver's licenses and ID cards if they are going to be acceptable for federal purposes, such as boarding a domestic commercial flight, or entering a military installation or federal facility that requires ID.",
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "Yes", val: "F" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Oregon",
          rev: "Rev. 07/09/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "00 or 01 + 7 digits",
            style: {
              placeholder: "8456732",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "679 S Birch St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "679 S Birch St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Sisters",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Sisters",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "97759",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "97759",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'AA' + 7 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "4 digits",
            style: {
              placeholder: "AA7088159",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "'200' + 6 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "200117013",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Pennsylvania",
          rev: "Rev. 06/07/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "95744057",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "857 Chandlee Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "857 Chandlee Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Smithfield",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "West Chester",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "19382",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "19382",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0250018505120106",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "2023967803632140000058560",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "number",
            name: "dups",
            label: "Dups",
            tip: null,
            auto: null,
            style: {
              placeholder: "00",
              min: 0,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "00",
            feedback: "Enter a valid value",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Rhode Island",
          rev: "Rev. 01/26/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: " You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "00 or 01 + 7 digits",
            style: {
              placeholder: "3755008",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "24 Rimwood Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "24 Rimwood Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Smithfield",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Smithfield",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "02917",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "02917",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "10",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "10",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "00700 + 11 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0070031972215065",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0070031972215065",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "4 digits. You can click on the calculator icon to calculate data <b>automatically!</b>",
            auto: "4 digits",
            style: {
              placeholder: "300283",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Nebraska (2017)",
          rev: "Rev. 02/28/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Letter (A, B, C, E, G, H or V) and 8 numbers. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "V33627544",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1801 N 31st St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1801 N 31st St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Lincoln",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Lincoln",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "68503",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "68503",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "0",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "0",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },

          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "0110 + 12 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "0100385619573853",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "0540000357800000",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Alaskan or American Indian", val: "AI" },
              { label: "Asian or Pacific Islander", val: "AP" },
              { label: "Black", val: "BK" },
              { label: "Hispanic Origin", val: "H" },
              { label: "Non-hispanic", val: "O" },
              { label: "Unknown", val: "U" },
              { label: "White", val: "W" },
            ],
            name: "race",
            label: "Race (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "Alaskan or American Indian", val: "AI" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Oklahoma",
          rev: "Rev. 01/13/2020",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Letter + 9 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "S576189157",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },

          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1927 N Broadway Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1927 N Broadway Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Shawnee",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Shawnee",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "748043721",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "748043721",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152024",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + OK + 4 letters + 01. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "S576189157OKYRSL01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Documunet number + Date of birth (MMDDYY) + issue date (MMDDYY) + letter. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "S576189157011570082620R",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Yes", val: "F" },
              { label: "No", val: "N" },
            ],
            name: "realID",
            label: "Is REAL ID",
            tip: "REAL ID is a federal law that affects how states issue driver's licenses and ID cards if they are going to be acceptable for federal purposes, such as boarding a domestic commercial flight, or entering a military installation or federal facility that requires ID.",
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "Yes", val: "F" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "South Carolina",
          rev: "Rev. 01/01/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "9 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "107844741",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "619 Bucks Trail",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "619 Bucks Trail",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Longs",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Longs",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "295688906",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "295688906",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "8513940203280844439",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Alaskan or American Indian", val: "AI" },
              { label: "Asian or Pacific Islander", val: "AP" },
              { label: "Black", val: "BK" },
              { label: "Hispanic Origin", val: "H" },
              { label: "Non-hispanic", val: "O" },
              { label: "Unknown", val: "U" },
              { label: "White", val: "W" },
            ],
            name: "race",
            label: "Race (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "Alaskan or American Indian", val: "AI" },
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 8,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "South Dakota",
          rev: "Rev. 06/29/2017",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "8 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "01384574",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "320 W Chapelwood Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "320 W Chapelwood Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Brandon",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Brandon",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "570052115",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "570052115",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "1",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "1",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "042000 + 10 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 8,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Tennessee",
          rev: "Rev. 12/02/2011",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "145065024",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "219 Gresham Ln",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "219 Gresham Ln",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Murfreesboro",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Murfreesboro",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "371294127",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "371294127",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "After 2016 - 8 years after issue. Expires on the day of issue. Until 2016, 3-7 years after issue. Expires on birthday.",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },

          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "YY issue year + number of days from beginnig of the year to issue date + '0101'. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "182381070085030101",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 digits + '0' + issue date (YYMMDD) + 7 random digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "4601808269812264",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "",
            feedback: "Please enter a weight",
          },
        ],
      },
      {
        header: {
          title: "Texas (2016)",
          rev: "Rev. 10/10/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "24253231",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "609 E Clare St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "609 E Clare St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Beeville",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Beeville",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "78102",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "78102",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262018",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "qqq",
            style: {
              placeholder: "01152023",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "20 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "19193295907082813411",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + 2 spaces. Then date of issue + 1 day  in YYYYMMDD format. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "94116090  20180228",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Please enter a weight",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Alaskan or American Indian", val: "AI" },
              { label: "Asian or Pacific Islander", val: "AP" },
              { label: "Black", val: "BK" },
              { label: "Hispanic Origin", val: "H" },
              { label: "Non-hispanic", val: "O" },
              { label: "Unknown", val: "U" },
              { label: "White", val: "W" },
            ],
            name: "race",
            label: "Race (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "Unknown", val: "U" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Texas (2020)",
          rev: "Rev. 02/23/2020",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "24253231",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "609 E Clare St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "609 E Clare St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Beeville",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Beeville",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "781024321",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "781024321",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "C",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "Valid issue period is 02/23/2020 - till now",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262021",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 5 years after the year of issue",
            auto: "null",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "20 random digits. <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "92334268049701405070",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'0000' + 7 random digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "10001846127",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Please enter a weight",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "Alaskan or American Indian", val: "AI" },
              { label: "Asian or Pacific Islander", val: "AP" },
              { label: "Black", val: "BK" },
              { label: "Hispanic Origin", val: "H" },
              { label: "Non-hispanic", val: "O" },
              { label: "Unknown", val: "U" },
              { label: "White", val: "W" },
            ],
            name: "race",
            label: "Race (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "Unknown", val: "U" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Utah",
          rev: "Rev. 04/15/2016",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "9 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 9 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "182162827",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "555 E Elm St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "555 E Elm St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Layton",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Layton",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "84041",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "84041",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. After January 2020 - 8 years after the year of issue. Before January 2020 - 5 years after the year of issue.",
            auto: "w",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },

          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + 'UT' + 4 digits and letters + '01'. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "182162827UTQ8CW01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "8 random digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "11 random digits + '00' + 5 random digits",
            style: {
              placeholder: "32656373",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BRO (Brown)", val: "BRO" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Vermont",
          rev: "Rev. 10/16/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "8 random digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "10784474",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "62 Charlotte Rd",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "62 Charlotte Rd",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Hinesburg",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Hinesburg",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "05461",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "05461",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262022",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 4 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "072022123412345678",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 8,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Virginia",
          rev: "Rev. 12/10/2008",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Letter + 8 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "H + 8 digts",
            style: {
              placeholder: "A37262186",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First & Middle name",
            tip: "Enter First name or First + Middle separated by commas.",
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "Donald,James",
              width: {
                md: 6,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1401 Wesanne Ln",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1401 Wesanne Ln",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Midlothian",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Midlothian",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "231143171",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "231143171",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'00601' + 11 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Issue date + 6 days (YYYYMMDD) + _053001_2_ + digits",
            style: {
              placeholder: "0060101096491223",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "'0' + 8 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "Issue date (YYYYMMDD) + digts and letters",
            style: {
              placeholder: "099750996",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 6,
              },
            },
            default: { label: "BRO (Brown)", val: "BRO" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Washington",
          rev: "Rev. 11/12/2019",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "WDL + 8 random digits and letters + B. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "2 letters + six digits + letter",
            style: {
              placeholder: "WDL0257B93TB",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "29810 224th Ave SE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "29810 224th Ave SE",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Kent",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Kent",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "980106509",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "980106509",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "12262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 6 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152026",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "Audit info",
            tip: "random letter + Date of issue (MMDDYY) + 6 random digits. You can click on the calculator icon to calculate data <b>automatically!</b>",
            auto: "000 + 7 digits",
            style: {
              placeholder: "R082620981133",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Document number + audit information <br> You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "WDL0257B93TBR082620981133",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 12,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Washington DC",
          rev: "Rev. 09/28/2017 (0303)",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "7 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "1957275",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "1424 Carrollsburg Pl SW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "1424 Carrollsburg Pl SW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Washington",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Washington",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "20024-4102",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "20024-4102",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "Audit info",
            tip: "You can click on the calculator icon to calculate data <b>automatically!</b>",
            auto: "000 + 7 digits",
            style: {
              placeholder: "08312020_949312_4_672",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "First 8 digits is issue date in YYYYMMDD format",
            style: {
              placeholder: "99423549",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "NONE", val: "NONE" },
              { label: "JR (Junior)", val: "JR" },
              { label: "SR (Senior)", val: "SR" },
              { label: "1ST or I (First)", val: "I" },
              { label: "2ND or II (Second)", val: "II" },
              { label: "3RD or III (Third)", val: "III" },
              { label: "4TH or IV (Fourth)", val: "IV" },
              { label: "5TH or V (Fifth)", val: "V" },
              { label: "6TH or VI (Sixth)", val: "VI" },
            ],
            name: "nameSuffix",
            label: "Name Suffix",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: { label: "NONE", val: "NONE" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "West Virginia",
          rev: "Rev. 06/04/2019 (0900)",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "F123785",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },

          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "2402 9th Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "2402 9th Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Huntington",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Huntington",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "25703",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "25703",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "E",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "08262020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "Document number + 'WV' + 4 letters + '01' You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate ICN number of DL of US",
            style: {
              placeholder: "F123785WVFLOY01",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "Date of birth (MMDDYY) + First letter of last name + first letter of first name + expiry data (YYDD). You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "First 8 digits is issue date in YYYYMMDD format",
            style: {
              placeholder: "011570DJ2601",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 4,
                sm: 12,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Wisconsin",
          rev: "Rev. 09/01/2015",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: " You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "3 digits + 2 letters + 4 digits",
            style: {
              placeholder: "D000-4607-0015-00",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "W169N10741 Redwood Ln",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "W169N10741 Redwood Ln",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Germantown",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Germantown",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "530223971",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "530223971",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "D",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "D",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. 8 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152028",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "'0130100' + 9 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "You should generate audit number of DL of US",
            style: {
              placeholder: "0130100816849209",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "'OT' + first letter of Last name + issue date (YYYYMMDD) + 8 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "OTDKF2020082610924996",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },

          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 3,
              },
            },
            default: { label: "BRO (Brown)", val: "BRO" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Wyoming",
          rev: "Rev. 11/14/2018",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: " You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "115919618",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alaska, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "2412 E 12th St",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "2412 E 12th St",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Cheyenne",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Cheyenne",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "82001",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "82001",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "select",
            options: ["No", "Yes"],
            name: "donor",
            label: "Donor",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 1,
                sm: 2,
              },
            },
            default: "No",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "01151970",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in MMDDYYYY format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "02212020",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "Same month and day as in birth date. After July 2019 5 years, before that 4 years after the year of issue",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to expiry date",
            style: {
              placeholder: "01152025",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the MMDDYYYY format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "10000 + 5 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: "Document number + issue year + number of days from the beginning of year (+3 day) + 0101",
            style: {
              placeholder: "1000038857",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "10000 + 5 digits. Same as ICN. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: "you should generate DD of DL of US",
            style: {
              placeholder: "1000038857",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a value or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (in.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "69",
              min: 30,
              max: 100,
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "69",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (lb.)",
            tip: null,
            auto: null,
            style: {
              placeholder: "160",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: "169",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 2,
                sm: 3,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
    ],
  },
  {
    country: "Canada",
    payload: [
      {
        header: {
          title: "Alberta",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "British Columbia",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Manitoba",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "BA-NL-ES-L200NA",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "708 Alfred Ave",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "708 Alfred Ave",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Winnipeg",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Winnipeg",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "R2W 1Y8",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "R2W 1Y8",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "5F",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "5F",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19700115",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20220821",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251021",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "9 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "519426452",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "519426452",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "9 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "823164527",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "823164527",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "New Brunswick",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Newfoundland and Labrador",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Northwest Territories",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Nova Scotia",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Nunavut",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Ontario",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "P5718-37583-29384",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First & Middle name",
            tip: "Enter First name or First + Middle separated by commas.",
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "Donald,James",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "482 Thorndale Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "482 Thorndale Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Waterloo",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Waterloo",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "N2T 1W5",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "N2T 1W5",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "G",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "G",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "7 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "3724285",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "3724285",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "GC7295472",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "GC7295472",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Prince Edward Island",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
      {
        header: {
          title: "Quebec",
          rev: "Rev. 2015-08-01 (0401)",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "Letter + 12 digits. You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "D5006-285648-00",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              placeholder: "Daniel",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "251 Rue St Paul",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "251 Rue St Paul",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Saint-Tite",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Saint-Tite",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "G0X 3H0",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "G0X 3H0",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "5 6A",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "5 6A",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "7 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "3724285",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "3724285",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "(No de référence). You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "PENX36RBU",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "PENX36RBU",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "Saskatchewan",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "32257271",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "28 Morris Dr",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "28 Morris Dr",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Saskatoon",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Saskatoon",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "S7L 3T9",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "S7L 3T9",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "5",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "5",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701115",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201127",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251130",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "ICN",
            label: "ICN",
            tip: "letter + 8 digits. You can click on the calculator icon to calculate ICN <b>automatically!</b>>",
            auto: null,
            style: {
              placeholder: "F51537835",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "F51537835",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "10 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "7496716472",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "7496716472",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
        ],
      },
      {
        header: {
          title: "The Yukon",
          rev: "",
        },
        items: [
          {
            type: "text",
            name: "DL_number",
            label: "DL Number",
            tip: "You can click on the calculator icon to calculate number <b>automatically!</b>",
            auto: "You should generate 7 random digits for DL number of Alabama, only need 7 digits",
            style: {
              placeholder: "153574-564",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            feedback: "Enter a number or click on the calculator icon.",
            required: true,
          },
          {
            type: "text",
            name: "last_name",
            label: "Last name",
            tip: null,
            auto: "You should generate last name of Alabama, US. only need last name",
            style: {
              placeholder: "Doe",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "first_name",
            label: "First name",
            tip: null,
            auto: "You should generate first name of Alabama, US. only need first name",
            style: {
              placeholder: "John",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "",
            required: true,
          },
          {
            type: "text",
            name: "middle_name",
            label: "Middle name",
            tip: "This field is optional. You can click on the calculator icon to generate random name. Select gender in the field below to specify name gender.",
            auto: "You should generate middle name of Alabama, US. only need middle name",
            style: {
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: false,
          },
          {
            type: "text",
            name: "address",
            label: "Address",
            tip: null,
            auto: null,
            style: {
              placeholder: "452 Scimitar Bay NW",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "452 Scimitar Bay NW",
            required: true,
          },
          {
            type: "text",
            name: "city",
            label: "City",
            tip: null,
            auto: null,
            style: {
              placeholder: "Calgary",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "Calgary",
            required: true,
          },
          {
            type: "text",
            name: "zipcode",
            label: "Zip code",
            tip: null,
            auto: null,
            style: {
              placeholder: "T3L 1S7",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "T3L 1S7",
            required: true,
          },
          {
            type: "text",
            name: "DL_class",
            label: "DL Class",
            tip: null,
            auto: null,
            style: {
              placeholder: "E",
              width: {
                md: 2,
                sm: 4,
              },
            },
            default: "C",
            required: true,
          },
          {
            type: "select",
            options: ["M", "F"],
            name: "sex",
            label: "Sex",
            tip: null,
            auto: null,
            style: {
              width: {
                md: 2,
                sm: 2,
              },
            },
            default: "M",
            required: true,
          },
          {
            type: "text",
            name: "birth_date",
            label: "Birth date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate birth date of DL in MMDDYYYY format, only need to birth date",
            style: {
              placeholder: "19701125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "issue_date",
            label: "Issue date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "You should generate issue date of DL in MMDDYYYY format, only need to issue date",
            style: {
              placeholder: "20201120",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "expiry_date",
            label: "Expiry date",
            tip: "You need input the date in YYYYMMDD format",
            auto: "expiry date",
            style: {
              placeholder: "20251125",
              width: {
                md: 4,
                sm: 4,
              },
            },
            default: "",
            feedback: "Requires a date in the YYYYMMDD format.",
            required: true,
          },
          {
            type: "text",
            name: "dd",
            label: "DD",
            tip: "2 letters and 7 digits. You can click on the calculator icon to calculate DD <b>automatically!</b>",
            auto: null,
            style: {
              placeholder: "0571-54946",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "0571-54946",
            required: true,
          },
          {
            type: "text",
            name: "restrictions",
            label: "Restrictions",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "text",
            name: "endorsement",
            label: "Endorsement",
            tip: null,
            auto: null,
            style: {
              placeholder: "NONE",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "NONE",
            required: true,
          },
          {
            type: "number",
            name: "height",
            label: "Height (cm)",
            tip: null,
            auto: null,
            style: {
              placeholder: "175",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "175",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "number",
            name: "weight",
            label: "Weight (kg)",
            tip: null,
            auto: null,
            style: {
              placeholder: "169",
              min: 30,
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: "160",
            feedback: "Enter a valid value",
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLU (Blue)", val: "BLU" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Gray)", val: "GRY" },
              { label: "GRN (Green)", val: "GRN" },
              { label: "HAZ (Hazel)", val: "HAZ" },
              { label: "MAR (Maroon)", val: "MAR" },
              { label: "PNK (Pink)", val: "PNK" },
              { label: "DIC (Dichromatic)", val: "DIC" },
              { label: "UNK (Unknown)", var: "UNK" },
            ],
            name: "eye",
            label: "Eye (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "select",
            options: [
              { label: "BLK (Black)", val: "BLK" },
              { label: "BLN (Blond)", val: "BLN" },
              { label: "BRO (Brown)", val: "BRO" },
              { label: "GRY (Grey)", val: "GRY" },
              { label: "RED (Red/Auburn)", val: "RED" },
              { label: "SDY (Sandy)", val: "SDY" },
              { label: "WHI (White)", val: "WHI" },
              { label: "UNK (Unknown)", val: "UNK" },
              { label: "BAL (Bald)", val: "BAL" },
            ],
            name: "hair",
            label: "Hair (ANSI D-20)",
            tip: null,
            auto: null,
            style: {
              placeholder: "BRO (Brown)",
              width: {
                md: 3,
                sm: 4,
              },
            },
            default: { label: "BLK (Black)", val: "BLK" },
            required: true,
          },
          {
            type: "text",
            name: "address2",
            label: "Address (2nd line)",
            tip: null,
            auto: null,
            style: {
              placeholder: "",
              width: {
                md: 3,
                sm: 6,
              },
            },
            default: "",
            feedback: "Enter a valid value",
          },
        ],
      },
    ],
  },
];
