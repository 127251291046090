//Barcode.js

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faCircleInfo, faL } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { states } from '../utils/utils';
// import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from '../utils/utils';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useAuth } from '../hooks/use-auth';
import Toast from 'react-bootstrap/Toast';

export default function Barcode(props) {

    const [payload, setPayload] = useState(null)
    const [validated, setValidated] = useState(false)
    const [data, setData] = useState({ middle_name: '', stateID: 'AR' })
    const [barcode_img, setBarcodeImg] = useState(false)
    const [showPayModal, setPayModal] = useState(false);
    const [showAlert, setPayAlert] = useState(false);
    // const [genNewData, setGenNewData] = useState(null)
    const [barcodeFilename, setFilename] = useState('')
    // const [barcodeImg, setImage] = useState(null)
    const barcodeRef = useRef(null)
    const [toastMsg, setToastMsg] = useState({ status: 'secondary', show: false, message: '' })

    const location = useLocation()
    const navigator = useNavigate()
    const auth = useAuth()

    const { region, country, code, revDate } = location.state
    console.log(revDate)

    // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

    const handleRandomAllInfo = async (item = '') => {
        try{
            let result = null
            const response = await axios.post("/api/generate/data?isRandomGeneration=true", { country, region: code, fullregion: region });
            result = response.data.result;
            document.getElementsByName('DL_number')[0].value = result.DL_number
            document.getElementsByName('first_name')[0].value = result.first_name
            document.getElementsByName('last_name')[0].value = result.last_name
            if(document.getElementsByName('middle_name').length > 0)
                document.getElementsByName('middle_name')[0].value = result.middle_name
            document.getElementsByName('birth_date')[0].value = result.birth_date
            document.getElementsByName('issue_date')[0].value = result.issue_date
            document.getElementsByName('expiry_date')[0].value = result.expiry_date
            if(document.getElementsByName('dd').length > 0)
                document.getElementsByName('dd')[0].value = result?.dd ? result?.dd : 'NONE'
            if(document.getElementsByName('ICN').length > 0)
                document.getElementsByName('ICN')[0].value = result?.ICN ? result?.ICN : ''
            let origin = data;
            const newData = Object.assign(origin, result)
            console.log('result: ', result)
            console.log('new data:', newData)

            if (region === "New York (2017)") {
                const names = result.first_name.split(',');
                result.first_name = names[0];
                result.middle_name = names.length > 1 ? names[1] : '';
            }

            
            setData({
                ...newData,
            })
        }catch(err){
            setToastMsg({ status: 'danger', show: true, message: 'Occured error!' })
            if(err?.response?.status === 403 || err?.response?.status === 454){
                // Barcode generation limit reached or not paid
                setPayAlert(true);
            } else {
                // Handle other errors as before
                console.log('unknown error', err);
            }
        }
    }

    const handleRandomInfo = async (item = '') => {
        try{
            let result = null
            const response = await axios.post("/api/generate/data?isRandomGeneration=true", { country, region: code, fullregion: region });
            result = response.data.result;
            let ele = document.getElementsByName(item)[0]
            if(item === 'last_name' || item === 'first_name' || item === 'middle_name' || item === 'birth_date' || item === 'issue_date'){
                console.log('here 1')
                ele.value = result[item]
                return true
            }
            if(item === 'expiry_date' && ele.value.length < 8){
                console.log('here')
                if(data?.birth_date?.length >= 8 && document.getElementsByName('issue_date')[0].value.length < 8){
                    ele.value = data?.birth_date.slice(0, 4)
                    console.log('hrere 111')
                }else if(document.getElementsByName('birth_date')[0].value === ''){
                    console.log('hee 222')
                    document.getElementsByName('birth_date')[0].value = result.birth_date
                    document.getElementsByName('issue_date')[0].value = result.issue_date
                }else if(document.getElementsByName('birth_date')[0].value.length >= 8 && document.getElementsByName('issue_date')[0].value.length >= 8){
                    console.log('hrere 333')
                    handleChange({target: { name: 'birth_date', value: document.getElementsByName('birth_date')[0].value }})
                }else{
                    console.log('hrere 444')
                    ele.value = result[item].slice(0, 4);
                    document.getElementsByName('birth_date')[0].value = result.birth_date;
                }
            }
            ele.value = result[item]
            setData({
                ...data,
                [item]: result[item]
            })
        }catch(err){
            if(err?.response?.status === 454){
                //not payed
                // console.log('you should pay')
                setPayAlert(true)
            }else if(err?.response?.status === 403){
                // auth failed
                // console.log('you should login')
                // navigator('/login')
            }else{
                // unknown error
                console.log('unknown error', err)
            }
        }
    }

    const draw = (barcode) => {
        let bw = 6;
        let bh = 3;
        let canvas = barcodeRef.current
        // var canvas = document.createElement('canvas');
        canvas.width = bw * barcode.num_cols;
        canvas.height = bh * barcode.num_rows;
        barcodeRef.current.innerHTML = ''
        // barcodeRef.current.appendChild(canvas);
        // document.getElementById('barcode').innerHTML = "";
        // document.getElementById('barcode').appendChild(canvas);
        let ctx = canvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        let y = 0;
        for (let r = 0; r < barcode.num_rows; ++r) {
            let x = 0;
            console.log('x: ', x)
            for (let c = 0; c < barcode.num_cols; ++c) {
                console.log('y: ', y)
                if (barcode.bcode[r][c] === '1') {
                    ctx.fillRect(x, y, bw, bh);
                }
                x += bw;
            }
            y += bh;
        }

        // document.getElementById("licenseNumber").innerHTML = "License Number: " + lin;
        // document.getElementById("issueDate").innerHTML = "Issue Date (MMDDYYYY): " + issueDate;
        // document.getElementById("expiryDate").innerHTML = "Expiry date (MMDDYYYY): " + expiryDate;
        // document.getElementById("classification").innerHTML = "License Class: " + classification;
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault()
            setPayModal(true)
        }
        setValidated(true);
    };

    const handleChange = (e) => {
        let biasExpiry = 0
        if(region === 'AL') biasExpiry = 5
        else if(region === 'Alabama') biasExpiry = 7
        else if(region === 'Alaska') biasExpiry = 6
        else if(region === 'Arizona') biasExpiry = 8
        else if(region === 'Arkansas') biasExpiry = 7
        else if(region === 'California (2017)') biasExpiry = 5
        else if(region === 'Colorado (2015)') biasExpiry = 7
        else if(region === 'Colorado (2022)') biasExpiry = 6
        else if(region === 'Connecticut') biasExpiry = 6
        else if(region === 'Delaware') biasExpiry = 8
        else if(region === 'Florida') biasExpiry = 5
        else if(region === 'Georgia (2015)') biasExpiry = 6
        else if(region === 'Georgia (2019)') biasExpiry = 6
        else if(region === 'Hawaii') biasExpiry = 8
        else if(region === 'Idaho') biasExpiry = 6
        else if(region === 'Illinois') biasExpiry = 5
        else if(region === 'Indiana') biasExpiry = 6
        else if(region === 'Iowa (2011)') biasExpiry = 8
        else if(region === 'Iowa (2017)') biasExpiry = 8
        else if(region === 'Kansas') biasExpiry = 6
        else if(region === 'Kentucky (2012)') biasExpiry = 8
        else if(region === 'Kentucky (2018)') biasExpiry = 4
        else if(region === 'Louisiana') biasExpiry = 6
        else if(region === 'Maine') biasExpiry = 6
        else if(region === 'Maryland') biasExpiry = 8
        else if(region === 'Massachusetts') biasExpiry = 5
        else if(region === 'Michigan') biasExpiry = 4
        else if(region === 'Minnesota') biasExpiry = 4
        else if(region === 'Mississippi') biasExpiry = 4
        else if(region === 'Missouri (2012)') biasExpiry = 6
        else if(region === 'Missouri (2020)') biasExpiry = 6
        else if(region === 'Montana') biasExpiry = 8
        else if(region === 'Nebraska (2017)') biasExpiry = 5
        else if(region === 'Nevada') biasExpiry = 6
        else if(region === 'New Hampshire') biasExpiry = 5
        else if(region === 'New Jersey (2020)') biasExpiry = 6
        else if(region === 'New Mexico') biasExpiry = 6
        else if(region === 'New York (2017)') biasExpiry = 8
        else if(region === 'New York (2022)') biasExpiry = 8
        else if(region === 'North Carolina') biasExpiry = 8
        else if(region === 'North Dakota') biasExpiry = 6
        else if(region === 'Ohio') biasExpiry = 6
        else if(region === 'Oklahoma') biasExpiry = 6
        else if(region === 'Oregon') biasExpiry = 6
        else if(region === 'Pennsylvania') biasExpiry = 6
        else if(region === 'Rhode Island') biasExpiry = 5
        else if(region === 'South Carolina') biasExpiry = 8
        else if(region === 'South Dakota') biasExpiry = 5
        else if(region === 'Tennessee') biasExpiry = 8
        else if(region === 'Texas (2016)') biasExpiry = 5
        else if(region === 'Texas (2020)') biasExpiry = 5
        else if(region === 'Utah') biasExpiry = 8
        else if(region === 'Vermont') biasExpiry = 4
        else if(region === 'Virginia') biasExpiry = 8
        else if(region === 'Washington') biasExpiry = 6
        else if(region === 'Washington DC') biasExpiry = 8
        else if(region === 'West Virginia') biasExpiry = 8
        else if(region === 'Wisconsin') biasExpiry = 8
        else if(region === 'Wyoming') biasExpiry = 5
        // if(e.target.name === 'birth_date' || e.target.name === 'issue_date'){
        //     if(document.getElementsByName('birth_date')[0].value.length >= 8 && document.getElementsByName('issue_date')[0].value.length >= 8){
        //         document.getElementsByName('expiry_date')[0].value = data?.birth_date.slice(0, 4) + (Number(document.getElementsByName('issue_date')[0].value.slice(4)) + biasExpiry)
        //         setData({
        //             ...data,
        //             expiry_date: data?.birth_date.slice(0, 4) + (Number(e.target.value.slice(4)) + biasExpiry)
        //         })
        //     }else{
        //         if(document.getElementsByName('issue_date')[0].value.length < 8){
        //             document.getElementsByName('expiry_date')[0].value = e.target.value.slice(0, e.target.value.length > 4 ? 4 : e.target.value.length)
        //             setData({
        //                 ...data,
        //                 [e.target.name]: e.target.value,
        //                 expiry_date: e.target.value.slice(0, e.target.value.length > 4 ? 4 : e.target.value.length)
        //             })
        //         }
        //     }
        // }
        // else if(e.target.name === 'birth_date'){
        //     document.getElementsByName('expiry_date')[0].value = e.target.value.slice(0, e.target.value.length > 4 ? 4 : e.target.value.length)
        //     setData({
        //         ...data,
        //         [e.target.name]: e.target.value,
        //         expiry_date: e.target.value.slice(0, e.target.value.length > 4 ? 4 : e.target.value.length)
        //     })
        // }
        // else if(e.target.name === 'issue_date' && e.target.value.length >= 8 && data?.birth_date?.length >= 4 ){
        //     document.getElementsByName('expiry_date')[0].value = data?.birth_date.slice(0, 4) + (Number(e.target.value.slice(4)) + biasExpiry)
        //     setData({
        //         ...data,
        //         expiry_date: data?.birth_date.slice(0, 4) + (Number(e.target.value.slice(4)) + biasExpiry)
        //     })
        // }

            if (region === "New York (2017)" && e.target.name === 'first_name') {
                const names = e.target.value.split(',');
                setData(prevData => ({
                    ...prevData,
                    first_name: names[0],
                    middle_name: names.length > 1 ? names[1] : '',
                }));
            } else {
                setData(prevData => ({
                    ...prevData,
                    [e.target.name]: e.target.value,
                }));
            }
        
        // setData({
        //     ...data,
        //     [e.target.name]: e.target.value,
        // })
    }

    const handlePurchase = async () => {
        //purchase using coinbase or e-commerce site
        try {
            console.log('input data: ', data)
            const rev = data?.rev_date ? data?.rev_date : revDate
            const bodyData = {
                ...data,
                country,
                revDate: rev,
            }
            const response = await axios.post(`/api/generate`, { data: bodyData })
            const result = response.data.result;
            console.log(result)
            setBarcodeImg(true)
            draw(result)
            const now = new Date()
            const time = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.png`; // You can customize the filename
            const image_name = `${data.first_name}-${data.middle_name}-${data.last_name}-${time}`
            setFilename(image_name)
            const canvas = barcodeRef.current;
            const image = canvas.toDataURL(`image/png`); // You can also use 'image/jpeg' for JPEG format
            const content = `First Name: ${data.first_name}, Last Name: ${data.last_name}, ` + (data.middle_name !== '' ? `Middle Name: ${data.middle_name}, ` : '')
                + `DL number: ${data.DL_number}, Birth date: ${data.birth_date}, Issue date: ${data.issue_date}, Expiry date: ${data.expiry_date}`
            const res1 = await axios.post('/api/history/store', {
                image,
                fileName: image_name,
                data: content,
                region: code
            });
            console.log('saved!')
            setToastMsg({ status: 'success', show: true, message: 'A barcode has been created!' })
        } catch (err) {
            setToastMsg({ status: 'danger', show: true, message: 'Occured error!' })
            if(err?.response?.status === 403 || err?.response?.status === 454){
                // Barcode generation limit reached or not paid
                setPayAlert(true);
            } else {
                // Handle other errors as before
                console.log('unknown error', err);
            }            
        }
        setPayModal(false)
    }

    const handleDownload = async (extension) => {
        // const response = await fetch(`${serverUrl}/api/download/image`, {
        //     method: 'POST',
        //     body: JSON.stringify({ imageUrl: barcode_img })
        // });
        // const blob = await response.blob();
        // console.log(blob)
        // // Create a download link
        // const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);

        // // Extract file name from response headers or provide a default name
        // // const contentDisposition = response.headers.get('content-disposition');
        // // const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        // // const fileName = fileNameMatch ? fileNameMatch[1] : 'downloaded_';

        // const filename = 'barcode_download'
        // link.download = filename + `.${extension}`;

        // // Append the link to the document and trigger the download
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const canvas = barcodeRef.current;
        const image = canvas.toDataURL(`image/${extension}`); // You can also use 'image/jpeg' for JPEG format
        
        const link = document.createElement('a');
        link.href = image;
        link.download = barcodeFilename + "." + extension
        link.click();
    }

    useEffect(() => {
        for (const element of states) {
            if (element.country === country) {
                for (const detail of element.payload) {
                    if (detail.header.title === region) {
                        let defaultValues = {}
                        detail.items.map(item => {
                            if (item.default) {
                                if(typeof(item.default) === 'string')
                                    defaultValues = {
                                        ...defaultValues,
                                        [item.name]: item.default
                                    }
                                else{
                                    if('val' in item.default){
                                        defaultValues = {
                                            ...defaultValues,
                                            [item.name]: item.default.val
                                        }
                                    }
                                }
                            }
                        })
                        console.log(defaultValues)
                        setPayload(detail);
                        setData({
                            ...data,
                            ...defaultValues,
                            stateID: code,
                            region
                        })
                        break;
                    }
                }
                break;
            }
        }
    }, [region])

    return (<>
        <div className='container my-3'>
        <div className='d-flex w-full justify-content-end position-fixed' style={{width: '86vw'}}>
            <Toast
            className="d-inline-block m-1"
            bg={toastMsg.status}
            onClose={() => setToastMsg({ show: false, status: 'secondary' })} show={toastMsg.show} delay={3000} autohide
            >
            <Toast.Body className={'text-white'}>
                {toastMsg.message}
            </Toast.Body>
            </Toast>
        </div>
            {payload && <div className='container'>
                <div>
                    <h3 className='text-center'>{payload.header.title}</h3>
                    <h6 className='text-center'>{payload.header.rev}</h6>
                </div>
                <div className='mt-3 rounded-3' style={{ backgroundColor: '#eee' }}>
                    <Alert className='text-center' show={showAlert} variant='danger' onClose={() => setPayAlert(false)} dismissible>
                        You don't have barcodes. Please <Link to='/pricing'>buy barcode packages</Link> to get access to generators.
                    </Alert>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3 p-3">
                            {payload.items.map((item, idx) => {
                                return (<Form.Group as={Col} md={item.style.width.md} sm={item.style.width.sm} controlId={`validationCustom-${idx}`} key={`barcode-form-group-${idx}`}>
                                    <Form.Label style={{ fontSize: 15 }}>
                                        {item.label}
                                        {item.tip !== null && <OverlayTrigger overlay={<Tooltip>{item.tip}</Tooltip>}>
                                            <FontAwesomeIcon className='ms-2' icon={faCircleInfo} />
                                        </OverlayTrigger>
                                        }
                                        {item.auto !== null && <a style={{ cursor: 'pointer' }} onClick={() => handleRandomInfo(item.name)}>
                                            <FontAwesomeIcon className='ms-2' icon={faCalculator} />
                                        </a>}
                                    </Form.Label>
                                    {item.type === 'text' ? <Form.Control key={`barcode-form-control-${idx}`} onChange={handleChange} required={item.required} maxLength={item.style?.maxLength} type={item.type} name={item.name} defaultValue={item.default} placeholder={item.style?.placeholder} /> : item.type === 'select' ? <Form.Select onChange={handleChange} required={item.required} name={item.name}>
                                        {
                                            item.options.map(value => typeof(value) === 'string' ? (<option value={value}>{value}</option>) : (<option value={value.val}>{value.label}</option>))
                                        }
                                    </Form.Select> : <Form.Control onChange={handleChange} min={item.style?.min} max={item.style.max} required={item.required} name={item.name} key={`barcode-form-number-input-${idx}`} type={item.type} defaultValue={item.default} />}
                                    {item.feedback && <Form.Control.Feedback type='invalid'>{item.feedback}</Form.Control.Feedback>}
                                </Form.Group>)
                            })}
                        </Row>
                        <div className='d-flex justify-content-center pb-3'>
                            <button type='button' className='btn btn-primary me-3' onClick={handleRandomAllInfo}>Random Generation</button>
                            <button type='submit' className='btn btn-primary'>Create</button>
                        </div>
                    </Form>
                </div>
                <div className='border round-2 text-center p-2 mt-3'>
                    <div className='container p-5' style={{display: barcode_img ? 'block' : 'none'}}>
                        {/* <img src={barcodeImg} alt='barcode image' style={{ minWidth: 300, width: 450 }} /> */}
                        <div id='barcode' className='d-flex justify-content-center'>
                            <div style={{minWidth: 350, width: 450}}>
                                <canvas ref={barcodeRef} style={{width: '100%', height: '100%', border: '1px solid #333'}}></canvas>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='d-flex justify-content-center'>
                                <Button variant='success' className='me-3' onClick={() => handleDownload('png')}>PNG</Button>
                                <Button variant='success' onClick={() => handleDownload('jpg')}>JPG</Button>
                            </div>
                        </div>
                    </div>
                    {
                        !barcode_img && <h5>
                            Fill in the fields and click the "Create" button and your barcode will appear here.
                        </h5>
                    }
                </div>
                <div className='text-center mt-5 mb-5'>
                    <h2 className='mt-5'>Need help?</h2>
                    <p>
                        Fist take a look at the <a href=''>article</a>, which describes how the site works in general.
                        If you have problem with barcode generator please take a look at this <a href=''>article</a>,
                        which will probably help you. If the issue is still not resolved or you have ane questions,
                        please <a href=''>contact us</a>. We will gladly help you!
                    </p>
                </div>
            </div>}
            <Modal show={showPayModal} onHide={() => setPayModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pb-5 pt-2 px-2'>
                        <p>Please make sure all fields are filled out correctly.<br/>
                            After creating the barcode, you cannot change the data!
                        </p>
                    </div>
                    <div className='d-grid'>
                        <Button variant='primary' onClick={handlePurchase}>Purchase</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </>
    )
}