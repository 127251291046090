//Register.js

import React from "react";
import { useAuth } from "../hooks/use-auth";

import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export default function Register() {

    const auth = useAuth()
    const navigator = useNavigate()

    const handleRegister = async (e) => {
        e.preventDefault()
        const form = e.target;
        const email = form.email.value;
        const pwd = form.password.value;
        const name = form.name.value;
        try {
            await auth.signUp(email, name, pwd);
            navigator('/login')
        } catch (err) {
            console.log('register error!')
        }
    }
    return (<div className='d-flex justify-content-center align-items-center bg-dark' style={{ width: '100vw', height: '100vh' }}>
        <Form onSubmit={handleRegister} style={{ minWidth: 450, maxWidth: 600, backgroundColor: '#fff', padding: 30 }}>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control required name='name' type="text" placeholder="Enter your name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control required name='email' type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control required name='password' minLength={8} type="password" placeholder="Password" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Button variant='link' onClick={() => navigator('/login')}>Go to login</Button>
            </Form.Group>
            <Button variant="primary" type="submit">
                Register
            </Button>
        </Form>
    </div>)
}