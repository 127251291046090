//AdminGuard.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth'; // Adjust the import path based on your structure

const AdminGuard = ({ children }) => {
    const { user, isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>; // Or your loading spinner
    }

    if (!isAuthenticated || user.role !== 'admin') {
        // Redirect to main if not authenticated or not admin
        return <Navigate to="/main" />;
    }

    return children;
};

export default AdminGuard;
