import { useState, useEffect } from 'react';

export const useConfig = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const response = await fetch('/config.json'); // Assuming config.json is in the public directory
      const config = await response.json();
      setConfig(config);
    };

    loadConfig();
  }, []);

  return config;
};
