//App.js

// import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Barcode from "./components/Barcode";
import Error from "./components/Error";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faDollarSign,
  faGear,
  faHouse,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Main from "./components/Main";
import Pricing from "./components/Pricing";
import Profile from "./components/Profile";
import Login from "./components/Login";
import Register from "./components/Register";
import AuthGuard from "./guards/AuthGuard";

import { AuthProvider } from "./contexts/auth-context";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import RedirectToLogin from "./components/RedirectLogin";
import Admin from "./components/Admin";
import { useEffect } from "react";
import AdminGuard from "./guards/AdminGuard";
import { useAuth } from "./hooks/use-auth";
import { Helmet } from "react-helmet";
import ChargesPage from "./components/Charges";
// import { Button } from 'react-bootstrap';

function useRouteStatus() {
  const location = useLocation(); // This is now within a hook and will not cause the error

  const getStatusFromPath = (pathname) => {
    const pathMap = {
      "/main": "main",
      "/states": "states",
      "/pricing": "pricing",
      "/profile": "profile",
      "/admin": "admin",
      // Add other mappings as needed
    };
    return pathMap[pathname] || "main"; // Default to 'main' if no match is found
  };

  return getStatusFromPath(location.pathname);
}

function AppContent() {
  const location = useLocation(); // Get the current location

  // A function to map pathnames to status values
  const getStatusFromPath = (pathname) => {
    const pathMap = {
      "/main": "main",
      "/states": "states",
      "/pricing": "pricing",
      "/profile": "profile",
      "/admin": "admin",
      // Add other mappings as needed
    };
    return pathMap[pathname] || "main"; // Default to 'main' if no match is found
  };

  const [status, handleNav] = useState(getStatusFromPath(location.pathname));
  const [adminPage, setAdminPage] = useState(false);

  const { user, isAuthenticated, isLoading } = useAuth(); // Destructure useAuth to get user and isAuthenticated

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      setAdminPage(isAuthenticated && user.role === "admin");
    }
  }, [isAuthenticated, user, isLoading]); // Add isLoading as a dependency

  useEffect(() => {
    // Listen for changes in the route
    handleNav(getStatusFromPath(location.pathname));
  }, [location.pathname]); // Depend on pathname to update navbar focus

  // if (isLoading) {
  //   return <div>Loading...</div>; // Show a loading indicator while the auth state is being initialized
  // }

  const customNavigate = (state) => {
    handleNav(state);
  };

  const handleDisableRightClickMenu = (e) => {
    if (e.button === 2) {
      e.preventDefault(); // Prevent the default right-click behavior
      return false;
    }
  };

  return (
    <div
      onContextMenu={handleDisableRightClickMenu}
      className="container px-0 h-full w-full overflow-hidden"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <nav
        className="navbar navbar-expand-lg bg-dark border-bottom border-body mt-4"
        style={{ padding: "12px 128px" }}
        data-bs-theme="dark"
      >
        <div className="container-fluid">
          <Link
            to={`/main`}
            className="navbar-brand"
            style={{ marginRight: 64 }}
            onClick={() => handleNav("main")}
          >
            <img src="/newlogo.png" alt="logo" width={300} className="me-5" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to={`/states`}
                  className={`nav-link me-4 ${
                    status === "states" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => handleNav("states")}
                >
                  <FontAwesomeIcon icon={faGear} />
                  <span className="ms-2">States</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/main`}
                  className={`nav-link me-4 ${
                    status === "main" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => handleNav("main")}
                >
                  <FontAwesomeIcon icon={faHouse} />
                  <span className="ms-2">Main</span>
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li> */}
              <li className="nav-item">
                <Link
                  to={`/pricing`}
                  className={`nav-link me-4 ${
                    status === "pricing" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => handleNav("pricing")}
                >
                  <FontAwesomeIcon icon={faDollarSign} />
                  <span className="ms-2">Pricing</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/profile`}
                  className={`nav-link me-4 ${
                    status === "profile" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => handleNav("profile")}
                >
                  <FontAwesomeIcon icon={faUser} />
                  <span className="ms-2">Profile</span>
                </Link>
              </li>
              {adminPage && (
                <li className="nav-item">
                  <Link
                    to={`/admin`}
                    className={`nav-link ${status === "admin" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => handleNav("admin")}
                  >
                    <FontAwesomeIcon icon={faChartLine} />
                    <span className="ms-2">Admin</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<RedirectToLogin />} />
        <Route path="/main" element={<Main setNavbar={handleNav} />} />
        <Route path="/states" element={<Home />} />
        <Route path="/barcode" element={<Barcode />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route
          path="/admin"
          element={
            <AdminGuard>
              <Admin />
            </AdminGuard>
          }
        />
        <Route
          path="/user-profile/:email"
          element={
            <AdminGuard>
              <Profile />
            </AdminGuard>
          }
        />
        <Route
          path="/admin/charges"
          element={
            <AdminGuard>
              <ChargesPage />
            </AdminGuard>
          }
        />

        <Route path="/login" element={<Login createAdmin={setAdminPage} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/error" element={<Error error={status} />} />
        <Route path="*" element={<Home />} />
        <Route
          path="/profile"
          element={
            <AuthGuard>
              <Profile showAdmin={setAdminPage} navigator={customNavigate} />
            </AuthGuard>
          }
        />
      </Routes>
      <div
        className="container-fluid text-center text-secondary p-2"
        style={{ backgroundColor: "#eee" }}
      >
        PDF417.IDTEMPL.COM © 2023 — 2026
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Helmet>
          <title>USA Driver License PDF417 and AAMVA Barcode Generator</title>
          <meta
            name="description"
            content="Best online AAMVA and PDF417 Driver's License Barcode Generator. PDF417.IDTEMPL.COM is a Scannable and powerful service for Creating 100% Valid PDF417 and AAMVA Barcodes Generated for USA and Canada all State Driver License."
          />
          <meta
            name="keywords"
            content="pdf417, pdf417 barcode generator, pdf417 barcode, pdf417 generator, barcode generator pdf417, pdf417 barcode drivers license generator software, pdf417 barcode generator for drivers license, aamva pdf417 barcode generator free, Free pdf417 barcode generator, pdf417 barcode generator free, aamva barcode generator pdf417 drivers license, how to make a pdf417 barcode, driver license barcode generator, driver's license barcode, USA driver license barcode, barcode on back of driver's license, US Driver License Barcode, USA driver license barcode generator, AAMVA barcode generator, pdf417 aamva barcode generator, aamva barcode generator, USA Driver License PDF417 and AAMVA Barcode Generator"
          />
        </Helmet>
        <AppContent />{" "}
        {/* This component now contains the main app logic and can use useRouteStatus safely */}
      </AuthProvider>
    </Router>
  );
}

export default App;
