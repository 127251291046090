import React, { useEffect, useState } from "react";
import axios from "../utils/utils";
import { Alert, Button, Form } from "react-bootstrap";

export default function ChargesPage() {
    const [charges, setCharges] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchCharges = async () => {
            try {
                const response = await axios.get(`/api/admin/charges?page=${currentPage}&limit=50&search=${searchTerm}`);
                setCharges(response.data.charges);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                console.error("Error fetching charges:", err);
            }
        };

        fetchCharges();
    }, [currentPage, searchTerm]);

    return (
        <div className="container mt-4">
            <h2>Payment Records</h2>
            <Form.Group className="mb-3" controlId="search">
                <Form.Control
                    type="text"
                    placeholder="Search by ID, Code, or Email"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Form.Group>
            {charges.length > 0 ? (
                <>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Charge ID</th>
                                <th>Code</th>
                                <th>User Email</th>
                                <th>Created At</th>
                                <th>Confirmed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {charges.map((charge, index) => (
                                <tr key={index}>
                                    <td>{charge.id}</td>
                                    <td>{charge.code}</td>
                                    <td>{charge.user_email}</td>
                                    <td>{new Date(charge.created_at).toLocaleString()}</td>
                                    <td>{charge.confirmed ? "Yes" : "No"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center"> {/* Center the pagination */}
                    <div className="text-center mt-2 me-2"> {/* Center the page indicator text */}
                        <small>Page {currentPage} out of {totalPages}</small>
                    </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                {[...Array(totalPages).keys()].map(number => (
                                    <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                        <Button className="page-link" onClick={() => setCurrentPage(number + 1)}>
                                            {number + 1}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </>
            ) : (
                <Alert variant="danger">No payment records found.</Alert>
            )}
        </div>
    );
}
