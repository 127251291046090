// AuthGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth'; // Adjust the import path based on your structure

const AuthGuard = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>; // Or your loading spinner
    }

    if (!isAuthenticated) {
        // Redirect to login if not authenticated
        return <Navigate to="/login" />;
    }

    return children;
};

export default AuthGuard;
